import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

export default function ResultadoConsulta({ apiResult }) {
  const [selectedDate, setSelectedDate] = useState('');  

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };


  if (!apiResult || apiResult.length === 0) {
    return (
      <Container>
        <Typography variant="h6" gutterBottom>No existe registro de afiliación.</Typography>
        <ul>
          <li>Verifique que ingresó el RNI correctamente.</li>
          <li>Si está afiliado y se muestra esta pantalla por favor envíe una solicitud de verificación por whatsapp al teléfono <a href='https://api.whatsapp.com/send?phone=+59172486048&text=Necesito%20consultar%20mi%20afiliaci%C3%B3n%20al%20CITI%20Oruro.'>72486048.</a></li>          
          <li>Para registrarse al CITI Oruro, a continuación te compartimos los requisitos, calculadora de aportes y formulario de registro.</li>
        </ul>

        

        <Typography fontWeight='bold'  variant="h6" gutterBottom>1. Requisitos afiliación al CITI Oruro y aportes:</Typography>

        <ul>
          <li>Registro en la SIB Oruro (RNI).</li>
          <li>Especialidad de Ingeniería en: Sistemas, Informática, Telecomunicaciones o áreas en Tecnologías de la Información.</li>
          <li>
            Aporte de afiliación o reafiliación.
            <ul>
              <li><Box fontWeight='bold' display='inline'>Afiliación.</Box> Colegas con registro en la SIB a partir de Junio 2022: 200Bs.</li>
              <li><Box fontWeight='bold' display='inline'>Reafiliación.</Box> Colegas con registro en la SIB anterior a Junio 2022: 50Bs.</li>
              
            </ul>
          </li>
          <li>Los aportes son mensuales de 10Bs. Reafiliados computables a partir de Junio 2022 y Afiliados computables a partir de su mes de registro en la SIB.
             </li>
             <li>Explora nuestros estatutos y reglamentos para conocer más sobre la integridad y transparencia de nuestra institución en el siguiente <Box fontWeight='bold' display='inline'><a href='https://citior.org.bo/estatutos-y-reglamentos/' target="_blank" rel="noopener noreferrer">enlace</a></Box>.
             </li>
          
        </ul>

                
        <Typography fontWeight='bold'  variant="h6" gutterBottom>2. Cálculo de Aportes:</Typography>
        <Typography variant="body1" gutterBottom>Consulta el mes de tu registro en el SIB en este enlace: <a href='https://www.sib.org.bo/servicios-en-linea/guia-de-ingenieros.html' target="_blank" rel="noopener noreferrer">Consultar Registro</a></Typography>
          <ul>
            <li><Box fontWeight='bold' display='inline'>Con Reafilición</Box> (Colegas con registro en la SIB anterior a Junio 2022)</li>
              <ul>
                <li>Reafiliación = 50Bs.</li>
                <li>Aportes (JUNIO DE 2022 a {formatMesAnio(getUltimoAporte())}) = 190Bs.
                <Typography variant="body1" gutterBottom>Total para el pago: <Box fontWeight='bold' display='inline'> {calculateAportesAfiliado('MAYO 2022', getUltimoAporte())+50}Bs. </Box> </Typography>
                </li>   
              </ul>                                  
              <li><Box fontWeight='bold' display='inline'>Con Afiliación</Box>  (Colegas con registro en la SIB a partir de Junio 2022)</li>
              <ul>
                <li>Reafiliación = 200Bs.</li>  
                <li>Ingrese mes de registro en la SIB:  <br/>
                <TextField
          id="standard-select-date"
          select
          label="Seleccione mes de registro a la SIB"
          value={selectedDate}
          onChange={handleDateChange}
          variant="standard"
          fullWidth  // Hace que el TextField ocupe todo el ancho disponible
          sx={{ minWidth: 200 }}  // Establece un ancho mínimo
        > 
        
        
        {Array.from({ length: calcularMesesDesdeJunio2022() }, (_, index) => {
          const date = new Date();
          date.setMonth(date.getMonth() - index);
          const formattedDate = date.toISOString().slice(0, 10);
          return (
            <MenuItem key={index} value={formattedDate}>
              {date.toLocaleDateString('es-ES', { month: 'long', year: 'numeric' })}
            </MenuItem>
          );
        })}
        </TextField>

        {selectedDate && (
        // Aquí puedes mostrar el cálculo de aportes basado en la fecha seleccionada
        <Box>
          <Typography variant="body1" gutterBottom>
            Total aportes desde {formatMesAnio(selectedDate)} hasta {formatMesAnio(getUltimoAporte())}: {calculateAportes(selectedDate)}Bs.
          </Typography>
          <Typography variant="body1" gutterBottom>
            Total para el pago: <Box fontWeight='bold' display='inline'>{200 + calculateAportes(selectedDate)}Bs.</Box>
          </Typography>
        </Box>
      )}

                  </li>
                
                
              </ul>
          </ul>

        <Typography fontWeight='bold'  variant="h6" gutterBottom>3. Cuenta de recaudación CITI Oruro:</Typography>
        <Typography variant="body1" gutterBottom>Para facilitar su pago puedes realizarlo al siguiente QR.</Typography>
        <div style={{ marginBottom: '20px' }}>
        <img src="img/QR_Recaudacion_CITI_OR.jpg" alt="QR recaudación" style={{ display: 'block', margin: 'auto', width: '90%', maxWidth: '100%', border: '1px solid black' }}/>   </div>   
        <div style={{ marginBottom: '20px' }}><Typography variant="body1" gutterBottom style={{ textAlign: 'justify' }}>Una vez realizada la transacción enviar el comprobante digital al celular: <a href='https://api.whatsapp.com/send?phone=+59172486048&text=Adjunto%20comprobante%20aporte%20CITI%20Oruro'>72486048</a>. La base de datos se actualizará una vez confirmada la transacción a: <Box fontWeight='bold' display='inline'>Ing. Paola Cleyder Alvarez Zambrana - Secretaría de Organización y Finanzas</Box>.</Typography></div>
        
        
        
        <Typography fontWeight='bold'  variant="h6" gutterBottom>4. Registro de afiliación:</Typography>

<ul>
  <li>Una vez que hayas verificado que cumples con todos los requisitos y hayas realizado el pago de afiliación/aportes llena el siguiente <Box fontWeight='bold' display='inline'><a href='https://citior.org.bo/registro-de-colegiado/' target="_blank" rel="noopener noreferrer">formulario</a></Box>.</li>  
            
</ul>
        
      </Container>  
    );
  }

  function calcularMesesDesdeJunio2022() {
    const startDate = new Date(2022, 5); // Meses en JavaScript son base 0, así que junio es 5
    const currentDate = new Date();
    const monthsDifference = (currentDate.getFullYear() - startDate.getFullYear()) * 12 + currentDate.getMonth() - startDate.getMonth();
    return monthsDifference +1;
  }

  function calculateAportes(selectedDate) {

    if (!selectedDate) {
      return 0; // Manejar el caso en que selectedDate esté vacío
    }

    const startDate = new Date(selectedDate);
    const endDate = getUltimoAporte();
    //const endDate = new Date('2023-12-31');
    const monthsDifference = (endDate.getFullYear() - startDate.getFullYear()) * 12 + endDate.getMonth() - startDate.getMonth() + 1;
    const totalAportes = monthsDifference * 10;
    return totalAportes;
  }

  const fechaRegistro = new Date(apiResult[0].fecha_registro);
  const options = { year: 'numeric', month: 'long' };
  let fechaFormateada = fechaRegistro.toLocaleDateString('es-ES', options);
  fechaFormateada = fechaFormateada.toUpperCase();

  return (
    <Container>
      <Typography fontWeight='bold'  variant="h6" gutterBottom>1. Registro de afiliación existente</Typography>
    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1, width: '1' },
      }}
      noValidate
      autoComplete="off"
    >


      
      <Typography variant="body" gutterBottom>
        El RNI tiene registro de afiliación al CITI Oruro:
      </Typography>
        <TextField
          id="standard-read-only-input1"
          label="RNI"
          defaultValue={apiResult[0].rni} 
          InputProps={{
            readOnly: true,
          }}
          variant="standard"
        />
        <TextField
          id="standard-read-only-input2"
          label="Nombre"
          defaultValue={apiResult[0].nombre} 
          InputProps={{
            readOnly: true,
          }}
          variant="standard"
        />
        <TextField
          id="standard-read-only-input3"
          label="Fecha afiliación"
          defaultValue={fechaFormateada} 
          InputProps={{
            readOnly: true,
          }}
          variant="standard"
        />
        <TextField
          id="standard-read-only-input4"
          label="Tipo afiliación"
          defaultValue={apiResult[0].reafiliacion ? 'REAFILIACIÓN' : 'AFILIACIÓN'}
          InputProps={{
            readOnly: true,
          }}
          variant="standard"
        />
        <TextField
          id="standard-read-only-input5"
          label="Último aporte"
          defaultValue={apiResult[0].ultimo_pago} 
          InputProps={{
            readOnly: true,
          }}
          variant="standard"
        />

      
      </Box>
      
      <Typography fontWeight='bold' variant="h6" gutterBottom>2. Cálculo de Aportes</Typography>

        {apiResult[0].ultimo_pago === 'SIN APORTES' && (
        <Box>
<Typography variant="body1" gutterBottom>
  Aportes pendientes desde <Box fontWeight='bold' display='inline'>{formatMesAnio(apiResult[0].fecha_registro)}</Box> hasta <Box fontWeight='bold' display='inline'>{formatMesAnio(getUltimoAporte())}</Box>.
</Typography>
        <Typography variant="body1" gutterBottom>
          Total a pagar: 
          <Box fontWeight='bold' display='inline'>
             {calculateAportesAfiliado(apiResult[0].ultimo_pago, apiResult[0].fecha_registro)}Bs.             
          </Box>
        </Typography>
        </Box>
        
      )}

{apiResult[0].ultimo_pago !== 'SIN APORTES' && (


<Box>

{calculateAportesAfiliado(apiResult[0].ultimo_pago, apiResult[0].fecha_registro)<=0 ? 
        
        <Typography variant="body1" gutterBottom>
        ¡En hora buena! El registro cuenta con aportes al día.        
        </Typography>                
        
            : 
            <Box>
      <Typography variant="body1" gutterBottom>
      Aportes pendientes desde <Box fontWeight='bold' display='inline'>{formatMesAnio(fechaAporte(apiResult[0].ultimo_pago))}</Box> hasta <Box fontWeight='bold' display='inline'>{formatMesAnio(getUltimoAporte())}</Box>.
      </Typography>
        <Typography variant="body1" gutterBottom>
          Total a pagar:
          <Box fontWeight='bold' display='inline'>
            {calculateAportesAfiliado(apiResult[0].ultimo_pago, apiResult[0].fecha_registro)}Bs.            
          </Box>
        </Typography>
        </Box>  
        
      }


</Box>
        
      )}



      <Typography fontWeight='bold'  variant="h6" gutterBottom>3. Cuenta de recaudación CITI Oruro</Typography>
        <Typography variant="body1" gutterBottom>Para facilitar su pago puedes realizarlo al siguiente QR.</Typography>

  
        <div style={{ marginBottom: '20px' }}>
        <img src="img/QR_Recaudacion_CITI_OR.jpg" alt="QR recaudación" style={{ display: 'block', margin: 'auto', width: '90%', maxWidth: '100%', border: '1px solid black' }}/>   </div>   
        <Typography variant="body1" gutterBottom style={{ textAlign: 'center' }}>Una vez realizada la transacción enviar el comprobante digital al celular: <a href='https://api.whatsapp.com/send?phone=+59172486048&text=Adjunto%20comprobante%20aporte%20CITI%20Oruro'>72486048</a>. La base de datos se actualizará una vez confirmada la transacción a:</Typography>
        <Typography variant="body1" gutterBottom style={{ textAlign: 'center' }}><Box fontWeight='bold' display='inline'>Ing. Paola Cleyder Alvarez Zambrana<br></br>Secretaría de Organización y Finanzas</Box></Typography>        
        
        

    
    </Container>
    
  );
}

function getUltimoAporte() {
  const today = new Date();
  const ultimoMes = new Date(today.getFullYear(), today.getMonth() - 1, 1);
  return ultimoMes;
  //const options = { year: 'numeric', month: 'long' };
  //return ultimoMes.toLocaleDateString('es-ES', options).toUpperCase();
}

function calculateAportesAfiliado(ultimoPago, fechaRegistro) {
// Verificar si la cadena es "SIN APORTES"
if (ultimoPago === 'SIN APORTES') {
  // Usar la fecha de registro como punto de inicio
  const fechaInicio = new Date(fechaRegistro);

  // Establecer diciembre de 2023 como la fecha de finalización
  //const fechaFin = new Date('December 31, 2023');
  //console.log(fechaFin);

  // Establecer MES ANTERIOR como la fecha de finalización
  const fechaFin = getUltimoAporte();
  //console.log(fechaFin);

  // Calcular la diferencia de meses
  const mesesDifference = (fechaFin.getFullYear() - fechaInicio.getFullYear()) * 12 + fechaFin.getMonth() - fechaInicio.getMonth() +1;

  // Calcular el monto total de los aportes
  const totalAportes = mesesDifference * 10;

  
  return totalAportes;
}

// Si no es "SIN APORTES", realizar el cálculo como antes
// Separar el mes y el año de la cadena "MES AÑO"
const [mes, año] = ultimoPago.split(' ');

// Mapear nombres de meses en español a inglés
const mesesEnEspanol = [
  'enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'
];

// Obtener el índice del mes
const mesIndex = mesesEnEspanol.indexOf(mes.toLowerCase());

// Crear una instancia de Date con el mes y año
const fechaUltimoPago = new Date(año, mesIndex);

// Establecer diciembre de 2023 como la fecha de finalización
//const fechaFin = new Date('December 31, 2023');
//console.log(fechaFin);

  // Establecer MES ANTERIOR como la fecha de finalización
const fechaFin = getUltimoAporte();
//console.log(fechaFin);

// Calcular la diferencia de meses
const mesesDifference = (fechaFin.getFullYear() - fechaUltimoPago.getFullYear()) * 12 + fechaFin.getMonth() - fechaUltimoPago.getMonth() ;

// Calcular el monto total de los aportes
const totalAportes = mesesDifference * 10;

return totalAportes;
}

function formatMesAnio(fecha) {
  const options = { month: 'long', year: 'numeric' };
  const fechaFormateada = new Date(fecha).toLocaleDateString('es-ES', options);
  return fechaFormateada.toUpperCase();
}

function fechaAporte(fecha) {
  const [mes, año] = fecha.split(' ');

// Mapear nombres de meses en español a inglés
const mesesEnEspanol = [
  'enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'
];

// Obtener el índice del mes
const mesIndex = mesesEnEspanol.indexOf(mes.toLowerCase());

// Crear una instancia de Date con el mes y año
const fechaAporte = new Date(año, mesIndex);
fechaAporte.setMonth(fechaAporte.getMonth() + 1);
return fechaAporte

}
